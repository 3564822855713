import {
    Pretend,
    IPretendRequest,
    Get,
    Post,
    Headers as PretendHeaders
} from 'pretend';

import { LocalDate, parseApiDates } from '@spa-frontend/date-lib';
import * as dtos from './dtos';

const parseResult =
    (props: string[]) =>
    (
        _target: Object,
        _key: string | symbol,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        descriptor: TypedPropertyDescriptor<any>
    ) => {
        const fn = descriptor.value;

        descriptor.value = async function (...args: unknown[]) {
            const result = await fn.apply(this, args);

            const tupleProps = props.map((prop) => prop.split('.'));

            return parseApiDates(result, tupleProps);
        };

        return descriptor;
    };

export interface ConnectApiBankAccountController {
    validateAccountHolderName(query: {
        accountHolder: string;
    }): Promise<dtos.ConnectApiBankAccountHolderValidationResult>;

    validateIbanAndLookupBic(query: {
        iban: string;
        organizationUnitId?: number;
    }): Promise<dtos.ConnectApiBankAccountBicIbanDto>;
}

export interface ConnectApiContractController {
    cancelContract(
        query: { recaptchaToken: string },
        dto: dtos.CancelContractRequestDto
    ): Promise<dtos.CancelContractResponseDto>;

    cancelContractManual(
        query: { recaptchaToken: string },
        dto: dtos.CancelContractManualRequestDto
    ): Promise<dtos.CancelContractManualResponseDto>;

    getActiveContracts(
        query: { recaptchaToken: string },
        dto: dtos.GetActiveContractsRequestDto
    ): Promise<dtos.GetActiveContractsResponseDto[]>;

    getStudioInfo(
        studioId: number
    ): Promise<dtos.ConnectApiContractCancellationStudioDto>;
}

export interface ConnectApiContractControllerV2 {
    getActiveStudiosInGermanyV2(): Promise<
        dtos.ConnectApiBasicStudioDataDtoV2[]
    >;
}

export interface ConnectApiContractPreviewController {
    getPreview_1(
        query: {
            landingPageConfigurationId?: number;
            checkoutPageConfigurationId?: number;
        },
        dto: dtos.ConnectApiCreateContractDto
    ): Promise<dtos.ConnectApiContractPreviewDto>;
}

export interface ConnectApiContractPreviewControllerV2 {
    getPreview(
        query: {
            landingPageConfigurationId?: number;
            checkoutPageConfigurationId?: number;
        },
        dto: dtos.ConnectApiCreateContractDto
    ): Promise<dtos.ConnectApiContractPreviewDto>;
}

export interface ConnectApiCreditCardTokenizationController {
    completeTokenization(
        tokenizationReference: string,
        dto: dtos.ConnectApiCompleteTokenizationDto
    ): Promise<dtos.AdyenCompleteTokenizationResponseDto>;

    initiateTokenizationV2(
        dto: dtos.ConnectApiInitiateTokenizationDtoV2
    ): Promise<dtos.AdyenInitiateTokenizationResponseDtoV2>;

    paymentMethodsV2(query: {
        studioId: number;
        countryCode: string;
        locale: string;
    }): Promise<dtos.PaymentMethodsResponseDto>;

    tokenizationState(
        tokenizationReference: string
    ): Promise<dtos.TokenizationStateDto>;
}

export interface ConnectApiFeatureCheckController {
    getActiveFeatures(query: {
        organizationUnitId: number;
    }): Promise<'MYSPORTS_MESSAGE'[]>;
}

export interface ConnectApiI18nController {
    getCountries(query: {
        locale: string;
    }): Promise<dtos.KeyValuePairCountryCodeString[]>;
}

export interface ConnectApiOnlinePagesController {
    getActiveStudiosForRateBundleTerm(
        configurationId: number,
        rateBundleTermId: number
    ): Promise<number[]>;

    getContractOffer(
        uuid: string
    ): Promise<dtos.ConnectApiOnlinePagesContractOfferDto>;

    getDiscountPreview(
        uuid: string,
        query: { customerDateOfBirth?: LocalDate }
    ): Promise<dtos.ConnectApiOnlinePagesContractOfferPreviewDto>;

    getOnlinePagesBasicConfigurationById(
        id: number
    ): Promise<dtos.OnlinePagesBasicConfigurationDto>;

    getOnlinePagesCheckoutConfigurationByUrl(
        url: string
    ): Promise<dtos.OnlinePagesCheckoutConfigDto>;

    getOnlinePagesCheckoutSocialByUrl(
        url: string,
        query: { source: 'OVERVIEW' | 'RATE' | 'TRIAL_SESSION' }
    ): Promise<dtos.SocialSharingInformationDto>;

    getOnlinePagesLandingPagesConfigurationByUrl(
        url: string
    ): Promise<dtos.OnlinePagesLandingPagesConfigDto>;

    getOnlinePagesLandingPagesSocialByUrl(
        url: string
    ): Promise<dtos.SocialSharingInformationDto>;

    getRateBundles(
        configurationId: number,
        query: { organizationUnitId: number }
    ): Promise<dtos.ConnectApiRateBundleDto[]>;
}

export interface ConnectApiPromotionController {
    confirmCustomerParticipation(query: { payload: string }): Promise<void>;
}

export interface ConnectApiTenantController {
    getTenantInfo(): Promise<dtos.TenantDto>;
}

export interface ConnectApiValidationController {
    validateTaxId(
        dto: dtos.ConnectApiTaxIdDto
    ): Promise<dtos.TaxIdValidationResultDto>;
}

export interface OnlinePagesLeadController {
    createLead(
        dto: dtos.ConnectApiCreateLeadDto
    ): Promise<dtos.ConnectApiCreateLeadResponseDto>;

    getLeadCustomerByUuid(
        uuid: string
    ): Promise<dtos.CommonTrialSessionLeadCustomerDto>;
}

export interface OnlinePagesRateBundleController {
    /**
     * @deprecated
     */
    createCustomerAndContract(
        dto: dtos.ConnectApiCreateContractDto
    ): Promise<dtos.ConnectApiCreateContractResponseDto>;

    createCustomerAndContract_1(
        originType:
            | 'CONFIGURATION_LINK'
            | 'CONNECT_API'
            | 'EXTERNAL'
            | 'LANDING_PAGE'
            | 'MYSPORTS_APP'
            | 'MYSPORTS_WEB'
            | 'NOX'
            | 'ONLINE_CHECKOUT_PAGE'
            | 'OPEN_API'
            | 'PERSONALIZED_OFFER_PAGE'
            | 'SALES_TOOL'
            | 'TOUCHPOINT'
            | 'WEBCLIENT',
        dto: dtos.ConnectApiCreateContractDto
    ): Promise<dtos.ConnectApiCreateContractResponseDto>;

    getPreSignContractCondition(query: {
        studioId: number;
    }): Promise<dtos.PreSignContractConditionDto>;

    getRateBundleList(query: {
        studioId: number;
        checkoutPageConfigurationId?: number;
    }): Promise<dtos.ConnectApiRateBundleDto[]>;

    getTextBlocks(
        rateBundleId: number,
        query: { organizationUnitId: number }
    ): Promise<dtos.ConnectApiSignableTextBlockDto[]>;

    signPersonalizedContractOffer(
        dto: dtos.ConnectApiCreateContractDto
    ): Promise<dtos.ConnectApiCreateContractResponseDto>;
}

export interface OnlinePagesStudioController {
    getSepaAgreementTextForRateBundle(
        studioId: number,
        rateBundleId: number,
        query: { language?: string }
    ): Promise<string>;

    getStudioDetails(
        studioId: number
    ): Promise<dtos.ConnectApiStudioDetailsDto>;

    getStudioList(query: {
        studioTags?: string[];
        addressSearchQuery?: string;
    }): Promise<dtos.ConnectApiStudioDtoV2[]>;
}

export interface OnlinePagesTrialSessionController {
    book(dto: dtos.ConnectApiBookTrialSessionDto): Promise<dtos.VoidResult>;

    getBookableItem(query: {
        studioId: number;
        startDate: LocalDate;
        endDate: LocalDate;
    }): Promise<dtos.ConnectApiBookableItemDto>;

    getLeadConfig(query: {
        studioId: number;
    }): Promise<dtos.ConnectApiTrialSessionLeadConfigDto>;
}

export interface OnlinePages {
    ConnectApiBankAccountController: ConnectApiBankAccountController;
    ConnectApiContractController: ConnectApiContractController;
    ConnectApiContractControllerV2: ConnectApiContractControllerV2;
    ConnectApiContractPreviewController: ConnectApiContractPreviewController;
    ConnectApiContractPreviewControllerV2: ConnectApiContractPreviewControllerV2;
    ConnectApiCreditCardTokenizationController: ConnectApiCreditCardTokenizationController;
    ConnectApiFeatureCheckController: ConnectApiFeatureCheckController;
    ConnectApiI18nController: ConnectApiI18nController;
    ConnectApiOnlinePagesController: ConnectApiOnlinePagesController;
    ConnectApiPromotionController: ConnectApiPromotionController;
    ConnectApiTenantController: ConnectApiTenantController;
    ConnectApiValidationController: ConnectApiValidationController;
    OnlinePagesLeadController: OnlinePagesLeadController;
    OnlinePagesRateBundleController: OnlinePagesRateBundleController;
    OnlinePagesStudioController: OnlinePagesStudioController;
    OnlinePagesTrialSessionController: OnlinePagesTrialSessionController;
}

/* eslint-disable @typescript-eslint/no-explicit-any */

class ConnectApiBankAccountControllerBlueprint
    implements ConnectApiBankAccountController
{
    @Get('/connect/v1/bankaccount/validate/accountholder', true)
    public validateAccountHolderName(_p0: any): any {
        /**/
    }

    @Get('/connect/v1/bankaccount', true)
    public validateIbanAndLookupBic(_p0: any): any {
        /**/
    }
}

class ConnectApiContractControllerBlueprint
    implements ConnectApiContractController
{
    @PretendHeaders('Content-Type: application/json')
    @parseResult([
        'cancellationDate',
        'cancellationReceivedDateTime',
        'dateOfBirth'
    ])
    @Post('/connect/v1/contracts/cancel', true)
    public cancelContract(_p0: any, _p1: any): any {
        /**/
    }

    @PretendHeaders('Content-Type: application/json')
    @parseResult(['cancellationDate', 'dateOfBirth', 'requestReceivedDate'])
    @Post('/connect/v1/contracts/cancel-request', true)
    public cancelContractManual(_p0: any, _p1: any): any {
        /**/
    }

    @PretendHeaders('Content-Type: application/json')
    @parseResult(['cancellationDates'])
    @Post('/connect/v1/contracts', true)
    public getActiveContracts(_p0: any, _p1: any): any {
        /**/
    }

    @Get('/connect/v1/contracts/studios/{studioId}')
    public getStudioInfo(_p0: any): any {
        /**/
    }
}

class ConnectApiContractControllerV2Blueprint
    implements ConnectApiContractControllerV2
{
    @Get('/connect/v2/contracts/studios')
    public getActiveStudiosInGermanyV2(): any {
        /**/
    }
}

class ConnectApiContractPreviewControllerBlueprint
    implements ConnectApiContractPreviewController
{
    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v1/preview', true)
    public getPreview_1(_p0: any, _p1: any): any {
        /**/
    }
}

class ConnectApiContractPreviewControllerV2Blueprint
    implements ConnectApiContractPreviewControllerV2
{
    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v2/preview', true)
    public getPreview(_p0: any, _p1: any): any {
        /**/
    }
}

class ConnectApiCreditCardTokenizationControllerBlueprint
    implements ConnectApiCreditCardTokenizationController
{
    @PretendHeaders('Content-Type: application/json')
    @Post(
        '/connect/v1/creditcard/tokenization/{tokenizationReference}/complete'
    )
    public completeTokenization(_p0: any, _p1: any): any {
        /**/
    }

    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v2/creditcard/tokenization/initiate')
    public initiateTokenizationV2(_p0: any): any {
        /**/
    }

    @Get('/connect/v2/creditcard/tokenization/payment-methods', true)
    public paymentMethodsV2(_p0: any): any {
        /**/
    }

    @Get('/connect/v1/creditcard/tokenization/{tokenizationReference}/state')
    public tokenizationState(_p0: any): any {
        /**/
    }
}

class ConnectApiFeatureCheckControllerBlueprint
    implements ConnectApiFeatureCheckController
{
    @Get('/connect/v1/featurecheck/activefeatures', true)
    public getActiveFeatures(_p0: any): any {
        /**/
    }
}

class ConnectApiI18nControllerBlueprint implements ConnectApiI18nController {
    @Get('/connect/v1/i18n/countries', true)
    public getCountries(_p0: any): any {
        /**/
    }
}

class ConnectApiOnlinePagesControllerBlueprint
    implements ConnectApiOnlinePagesController
{
    @Get('/connect/v1/onlinepages/{configurationId}/studios/{rateBundleTermId}')
    public getActiveStudiosForRateBundleTerm(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'customerDto.dateOfBirth',
        'endDate',
        'rateBundle.limitedOfferingPeriod.endDate',
        'rateBundle.limitedOfferingPeriod.startDate',
        'rateBundle.terms.contractStartDateOfUse',
        'rateBundle.terms.defaultContractStartDate',
        'rateBundle.terms.subsequentRateDto.limitedOfferingPeriod.endDate',
        'rateBundle.terms.subsequentRateDto.limitedOfferingPeriod.startDate',
        'rateBundle.terms.subsequentRateDto.terms.contractStartDateOfUse',
        'rateBundle.terms.subsequentRateDto.terms.defaultContractStartDate'
    ])
    @Get('/connect/v1/onlinepages/offers/{uuid}')
    public getContractOffer(_p0: any): any {
        /**/
    }

    @Get('/connect/v1/onlinepages/offers/{uuid}/discount-preview', true)
    public getDiscountPreview(_p0: any, _p1: any): any {
        /**/
    }

    @Get('/connect/v1/onlinepages/basicconfiguration/{id}')
    public getOnlinePagesBasicConfigurationById(_p0: any): any {
        /**/
    }

    @parseResult([
        'locationOverviewPageConfigDto.legalOrganizationUnitDto.addressDto.status.since'
    ])
    @Get('/connect/v1/onlinepages/checkoutpages/{url}')
    public getOnlinePagesCheckoutConfigurationByUrl(_p0: any): any {
        /**/
    }

    @Get('/connect/v1/onlinepages/checkoutpages/{url}/social', true)
    public getOnlinePagesCheckoutSocialByUrl(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'campaign.timePeriodDto.endDate',
        'campaign.timePeriodDto.startDate',
        'legalOrganizationUnitDto.addressDto.status.since'
    ])
    @Get('/connect/v1/onlinepages/landingpages/{url}')
    public getOnlinePagesLandingPagesConfigurationByUrl(_p0: any): any {
        /**/
    }

    @Get('/connect/v1/onlinepages/landingpages/{url}/social')
    public getOnlinePagesLandingPagesSocialByUrl(_p0: any): any {
        /**/
    }

    @parseResult([
        'limitedOfferingPeriod.endDate',
        'limitedOfferingPeriod.startDate',
        'terms.contractStartDateOfUse',
        'terms.defaultContractStartDate',
        'terms.subsequentRateDto.limitedOfferingPeriod.endDate',
        'terms.subsequentRateDto.limitedOfferingPeriod.startDate',
        'terms.subsequentRateDto.terms.contractStartDateOfUse',
        'terms.subsequentRateDto.terms.defaultContractStartDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.limitedOfferingPeriod.endDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.limitedOfferingPeriod.startDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.terms.contractStartDateOfUse',
        'terms.subsequentRateDto.terms.subsequentRateDto.terms.defaultContractStartDate'
    ])
    @Get(
        '/connect/v1/onlinepages/landingpages/{configurationId}/ratebundles',
        true
    )
    public getRateBundles(_p0: any, _p1: any): any {
        /**/
    }
}

class ConnectApiPromotionControllerBlueprint
    implements ConnectApiPromotionController
{
    @Post('/connect/v1/promotion/confirm-participation', true)
    public confirmCustomerParticipation(_p0: any): any {
        /**/
    }
}

class ConnectApiTenantControllerBlueprint
    implements ConnectApiTenantController
{
    @Get('/connect/v1/tenant')
    public getTenantInfo(): any {
        /**/
    }
}

class ConnectApiValidationControllerBlueprint
    implements ConnectApiValidationController
{
    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v1/validation/taxId')
    public validateTaxId(_p0: any): any {
        /**/
    }
}

class OnlinePagesLeadControllerBlueprint implements OnlinePagesLeadController {
    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v1/onlinepages/lead')
    public createLead(_p0: any): any {
        /**/
    }

    @parseResult(['dateOfBirth'])
    @Get('/connect/v1/onlinepages/lead/{uuid}')
    public getLeadCustomerByUuid(_p0: any): any {
        /**/
    }
}

class OnlinePagesRateBundleControllerBlueprint
    implements OnlinePagesRateBundleController
{
    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v1/onlinepages/rate-bundle')
    public createCustomerAndContract(_p0: any): any {
        /**/
    }

    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v1/onlinepages/rate-bundle/{originType}')
    public createCustomerAndContract_1(_p0: any, _p1: any): any {
        /**/
    }

    @Get('/connect/v1/onlinepages/rate-bundle/preSignContractCondition', true)
    public getPreSignContractCondition(_p0: any): any {
        /**/
    }

    @parseResult([
        'limitedOfferingPeriod.endDate',
        'limitedOfferingPeriod.startDate',
        'terms.contractStartDateOfUse',
        'terms.defaultContractStartDate',
        'terms.subsequentRateDto.limitedOfferingPeriod.endDate',
        'terms.subsequentRateDto.limitedOfferingPeriod.startDate',
        'terms.subsequentRateDto.terms.contractStartDateOfUse',
        'terms.subsequentRateDto.terms.defaultContractStartDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.limitedOfferingPeriod.endDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.limitedOfferingPeriod.startDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.terms.contractStartDateOfUse',
        'terms.subsequentRateDto.terms.subsequentRateDto.terms.defaultContractStartDate'
    ])
    @Get('/connect/v1/onlinepages/rate-bundle', true)
    public getRateBundleList(_p0: any): any {
        /**/
    }

    @Get('/connect/v1/onlinepages/rate-bundle/{rateBundleId}', true)
    public getTextBlocks(_p0: any, _p1: any): any {
        /**/
    }

    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v1/onlinepages/rate-bundle/poco')
    public signPersonalizedContractOffer(_p0: any): any {
        /**/
    }
}

class OnlinePagesStudioControllerBlueprint
    implements OnlinePagesStudioController
{
    @Get(
        '/connect/v1/onlinepages/studio/{studioId}/rate/{rateBundleId}/sepa/agreement',
        true
    )
    public getSepaAgreementTextForRateBundle(
        _p0: any,
        _p1: any,
        _p2: any
    ): any {
        /**/
    }

    @parseResult(['closingDate', 'openingDate'])
    @Get('/connect/v1/onlinepages/studio/details/{studioId}')
    public getStudioDetails(_p0: any): any {
        /**/
    }

    @parseResult(['closingDate', 'openingDate'])
    @Get('/connect/v1/onlinepages/studio', true)
    public getStudioList(_p0: any): any {
        /**/
    }
}

class OnlinePagesTrialSessionControllerBlueprint
    implements OnlinePagesTrialSessionController
{
    @PretendHeaders('Content-Type: application/json')
    @Post('/connect/v1/onlinepages/trialsession/book')
    public book(_p0: any): any {
        /**/
    }

    @parseResult(['slots.endDateTime', 'slots.startDateTime'])
    @Get('/connect/v1/onlinepages/trialsession', true)
    public getBookableItem(_p0: any): any {
        /**/
    }

    @Get('/connect/v1/onlinepages/trialsession/config/validation', true)
    public getLeadConfig(_p0: any): any {
        /**/
    }
}

/* eslint-enable @typescript-eslint/no-explicit-any */

export function getClient(
    endpoint: string,
    token: string | undefined,
    configure: (client: Pretend) => Pretend = (client) => client
): OnlinePages {
    const bearerToken = (request: IPretendRequest) => {
        if (token) {
            request.options.headers = new Headers(request.options.headers);
            request.options.headers.set('Authorization', `bearer ${token}`);
        }
        return request;
    };

    return {
        ConnectApiBankAccountController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiBankAccountControllerBlueprint, endpoint),
        ConnectApiContractController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiContractControllerBlueprint, endpoint),
        ConnectApiContractControllerV2: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiContractControllerV2Blueprint, endpoint),
        ConnectApiContractPreviewController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiContractPreviewControllerBlueprint, endpoint),
        ConnectApiContractPreviewControllerV2: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiContractPreviewControllerV2Blueprint, endpoint),
        ConnectApiCreditCardTokenizationController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(
                ConnectApiCreditCardTokenizationControllerBlueprint,
                endpoint
            ),
        ConnectApiFeatureCheckController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiFeatureCheckControllerBlueprint, endpoint),
        ConnectApiI18nController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiI18nControllerBlueprint, endpoint),
        ConnectApiOnlinePagesController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiOnlinePagesControllerBlueprint, endpoint),
        ConnectApiPromotionController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiPromotionControllerBlueprint, endpoint),
        ConnectApiTenantController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiTenantControllerBlueprint, endpoint),
        ConnectApiValidationController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiValidationControllerBlueprint, endpoint),
        OnlinePagesLeadController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(OnlinePagesLeadControllerBlueprint, endpoint),
        OnlinePagesRateBundleController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(OnlinePagesRateBundleControllerBlueprint, endpoint),
        OnlinePagesStudioController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(OnlinePagesStudioControllerBlueprint, endpoint),
        OnlinePagesTrialSessionController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(OnlinePagesTrialSessionControllerBlueprint, endpoint)
    };
}
